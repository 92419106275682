<template>
  <x-dialog
    v-model="isOpen"
    :close-sign="true"
    :cancel-text="null"
    :persistent="false"
    max-width="500"
    :confirm-text="null"
    @confirm="e => $emit('confirm', e)"
  >
    <div class="dialog-normal">
      <!-- BEGIN template name -->
      <div class="templateName">
        <v-chip
          label
          disabled
        >
          <span class="chipContent">
            Template Name
          </span>
        </v-chip>
        <span class="name">{{ title }}</span>
      </div>
      <!-- END template name -->

      <div class="content">
        {{ content }}
      </div>
    </div>
  </x-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    isOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('confirm', val)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.dialog-normal {
  .content {
    margin-bottom: 0;
  }
}

.templateName {
  .v-chip {
    font-size: $fs-small;
    font-weight: 600;
    background: $pale-blue !important;
    color: $bluey-grey !important;
    margin-left: 0;
  }
  .name {
    color: $label-grey;
    font-size: $fs-small;
    word-break: break-word;
  }
}

@media screen and (min-width: 480px) {
  .templateName {
    display: flex;
    align-items: center !important;

    .name {
      padding-left: 8px;
    }
  }
}

@media screen and (max-width: 480px) {
  .templateName {
    padding: 0 20px 0 0;

    .v-chip {
      font-size: $fs-mini;
      height: 18px;
      background: transparent !important;

      .chipContent {
        margin: 0 -12px;
      }
      &::after {
        content: " : ";
      }
    }
    .name {
      font-size: $fs-mini;
    }
  }
}
</style>
