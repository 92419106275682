<template>
  <x-dialog
    v-model="isOpen"
    v-bind="dialogSendingTest"
    @confirm="submit"
  >
    <h3
      slot="slot-header"
      class="slot-header title"
    >
      Sending Test
    </h3>
    <x-formgroup
      ref="form"
      class="w100 addMobileNumber"
      @submit.prevent="() => $refs.submitButton.click()"
    >
      <x-textfield
        v-if="isDevEnv"
        v-model="company.countryCode"
      />
      <x-textfield
        v-model="textFieldScheme.value"
        class="layout flex sm12 column"
        :error-messages="errorMessages"
        v-bind="textFieldScheme"
      />

      <x-button
        ref="submitButton"
        content="Add"
        size="large"
        :outline="true"
        prepend-icon="add"
        class="btnPrimary"
        :click="addReceiver"
      />
    </x-formgroup>

    <div
      v-if="selectedReceivers.length"
      class="receiver bgPaleBlue pa-3"
    >
      <ul>
        <li
          v-for="(receiver, ind) in selectedReceivers"
          :key="receiver"
          class="pt-1"
        >
          <p>
            <span class="pl-1">
              {{ type === 'SMS' ? `+${company.countryCode}` : '' }}
              {{ receiver }}
            </span>
          </p>
          <v-spacer />
          <x-button
            content="close"
            :icon="true"
            :round="true"
            size="small"
            class="btnWarning btnIconXs"
            :click="() => removeReceiver(ind)"
          />
        </li>
      </ul>
    </div>
  </x-dialog>
</template>

<script>
import { notEmpty, isEmail, isValidMobile } from '@/utils/validation'
import { convertTemplateToHtml } from '@/components/SectionUI/const/sectionHtml'
import company from '@/mixins/company'

export default {
  mixins: [company],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true,
      validator: value => {
        return ['SMS', 'EDM', 'APP_PUSH'].includes(value)
      }
    },
    templateId: {
      type: String,
      required: true
    },
    templateSubject: {
      type: String,
      default: ''
    },
    templateContent: {
      type: [String, Object],
      required: true
    }
  },
  data () {
    return {
      isDevEnv: process.env.NODE_ENV === 'development',
      selectedReceivers: [],
      errorMessages: '',
      dialogSendingTest: {
        cancelText: 'CANCEL',
        confirmText: 'SEND',
        closeSign: true,
        maxWidth: '620px'
      }
    }
  },
  computed: {
    isOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.selectedReceivers = []
        this.errorMessages = ''
        this.$emit('input', val)
      }
    },
    textFieldScheme () {
      const shared = {
        type: 'text',
        name: 'receiver',
        value: ''
      }
      if (this.type === 'SMS') {
        return {
          ...shared,
          label: 'Enter up to 5 mobile number(s)',
          prefix: `+${this.company.countryCode}`,
          placeholder: 'Enter mobile number',
          appendIcon: 'phone',
          rules: [notEmpty, isValidMobile(this.company.countryCode)].map(x =>
            x.bind(this)
          )
        }
      }
      if (this.type === 'APP_PUSH') {
        return {
          ...shared,
          label: 'Enter up to 5 user ID(s)',
          placeholder: 'Enter user ID',
          rules: [notEmpty].map(x => x.bind(this))
        }
      }
      return {
        ...shared,
        label: 'Enter up to 5 email(s)',
        placeholder: 'Enter email',
        appendIcon: 'email',
        rules: [notEmpty, isEmail].map(x => x.bind(this))
      }
    }
  },
  methods: {
    addReceiver () {
      this.errorMessages = ''
      if (!this.$refs.form.$children[0].validate()) {
        return
      }
      if (this.selectedReceivers.length >= 5) {
        return (this.errorMessages = "You've input 5 receivers")
      }
      const value = this.textFieldScheme.value
      if (this.selectedReceivers.includes(value)) {
        return (this.errorMessages = 'This receiver has already been added')
      }
      this.selectedReceivers.push(value)
    },
    removeReceiver (ind) {
      this.selectedReceivers.splice(ind, 1)
    },
    getContent () {
      switch (this.type) {
        case 'EDM':
          return convertTemplateToHtml(JSON.parse(this.templateContent))
        case 'APP_PUSH':
          return JSON.parse(this.templateContent)
        case 'SMS':
        default:
          return this.templateContent
      }
    },
    async submit () {
      if (this.$store.loading || !this.value) return false
      if (!this.selectedReceivers.length) {
        return (this.errorMessages = 'Please add receiver(s)')
      }
      this.$store.loading = true
      try {
        const { data } = await this.$Task.preflight({
          recipients: this.selectedReceivers,
          content: this.getContent(),
          countryCode: this.company.countryCode,
          templateId: this.templateId,
          subject: `[Test] ${this.templateSubject} (TemplateId: ${
            this.templateId
          })`,
          channel: this.type
        })
        if (data.status === 'ERROR') {
          throw data.message
        } else {
          this.$store.successNotification =
            'Test run has been sent successfully'
          this.$emit('input', false)
        }
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>

@media (min-width: 768px) {
  .dialog-box {
    .addMobileNumber {
      display: inline-flex;

      button.btnPrimary {
        margin-top: 21px;
      }
    }
  }
}

.receiver {
  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      display: flex;

      p {
        margin: 0;

        a {
          text-decoration: none;

          i {
            position: relative;
            top: 4px;
          }
        }
      }
    }
  }
}
</style>
