<template>
  <x-dialog
    v-model="isOpen"
    cancel-text=""
    confirm-text=""
    :close-sign="true"
    :max-width="previewWidth"
    @confirm="e => $emit('confirm', e)"
  >
    <!-- <h3
        slot="slot-header"
        class="slot-header title"
      >
        Edm Preview
      </h3> -->
    <div class="contentWrap">
      <span class="deviceIcon">
        <span @click="changePreviewWidth(defautSize.previewPcWidth)">
          <x-button
            v-bind="deviceButtonScheme"
            content="desktop_windows"
            :class="{
              deviceButtonActive:
                previewContentWidth === defautSize.previewPcWidth
            }"
          />
        </span>

        <span @click="changePreviewWidth(defautSize.previewMobileWidth)">
          <x-button
            v-bind="deviceButtonScheme"
            content="phone_android"
            :class="{
              deviceButtonActive:
                previewContentWidth === defautSize.previewMobileWidth
            }"
          />
        </span>
      </span>

      <div class="mt-3 containerOfPreviewSec">
        <!-- BEGIN mail subject -->
        <div class="subjectWrap">
          <v-chip
            label
            disabled
          >
            <span class="chipContent">
              <v-avatar class="chipSubject hidden-xs-only">
                <v-icon>mail</v-icon>
              </v-avatar>
              Subject
            </span>
          </v-chip>
          <span class="subject">{{ subject }}</span>
        </div>
        <!-- END mail subject -->

        <iframe
          ref="previewIframe"
          :width="previewContentWidth + 30"
          style="min-height: 60vh"
          frameborder="0"
        />
        <!-- <table>
        <section-header />

        <section-title />
        <component
          :is="sectionComponent(section.type)"
          v-for="(section, index) in sectionsList"
          :key="index"
          :handled-section="section"
          :on-preview="true"
        />
        <section-footer />
      </table> -->
      </div>
    </div>
  </x-dialog>
</template>

<script>
// import EdmCashaloKeep from '@/components/EdmCashaloKeep'
import {
  // typeComponentMapping,
  DEFAULT_SIZE
} from '@/components/SectionUI/const/sections'

import { convertTemplateToHtml } from '@/components/SectionUI/const/sectionHtml'

// import SectionHeader from '@/components/SectionUI/SectionItems/SectionHeader'
// import SectionTitle from '@/components/SectionUI/SectionItems/SectionTitle'
// import SectionFooter from '@/components/SectionUI/SectionFooter'

export default {
  components: {
    // SectionHeader,
    // SectionTitle
    // SectionFooter
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    subject: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      deviceButtonScheme: {
        square: true,
        size: 'small',
        outline: true
      },
      previewContentWidth: DEFAULT_SIZE.previewPcWidth,
      defautSize: DEFAULT_SIZE
      // exportHtml: ''
    }
  },
  computed: {
    isOpen: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('confirm', val)
      }
    },
    sectionsList () {
      return this.$store.builderProperty.rowSectionList
    },
    exportHtml () {
      return convertTemplateToHtml(this.$store.builderProperty.rowSectionList)
    },
    previewWidth () {
      const previewPadding = 110
      return (this.previewContentWidth + previewPadding).toString()
    }
  },
  watch: {
    value () {
      this.updateIframeContent()
    }
  },
  mounted () {
    this.updateIframeContent()
  },
  methods: {
    updateIframeContent () {
      var doc = this.$refs.previewIframe.contentWindow.document
      doc.open()
      doc.write(this.exportHtml)
      doc.close()
    },
    // sectionComponent (type) {
    //   return typeComponentMapping[type]
    // },
    changePreviewWidth (width) {
      this.previewContentWidth = width
    }
  }
}
</script>

<style lang="stylus" scoped>
// device icon

.contentWrap {
  .deviceIcon {
    .v-btn {
      border-radius: 4px;
      color: $silver !important;

      &.deviceButtonActive {
        color: $primary-imp;
      }
    }
  }
}
@media only screen and (min-width: 769px) {
  .contentWrap {
    position: relative;

    .deviceIcon {
      position: absolute;
      right: 6px;
      top: -48px;
    }
    .containerOfPreviewSec {
        display: table;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -20px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .contentWrap {
    .deviceIcon {
      display: none;
    }
    .containerOfPreviewSec {
      margin: 0 -15px -20px;

      iframe {
        width: 100%;
      }
    }
  }
}

// subject
.subjectWrap {
  .v-chip {
    font-size: $fs-small;
    font-weight: 600;
    background: $pale-blue !important;
    color: $bluey-grey !important;

    .chipSubject {
      margin-right: 0;

      .v-icon {
        font-size: 17px;
      }
    }
  }
  .subject {
    color: $label-grey;
    font-size: $fs-small;
  }
}

@media screen and (min-width: 480px) {
  .subjectWrap {
    display: flex;
    align-items: center !important;

    .subject {
      padding-left: 8px;
    }
  }
}

@media screen and (max-width: 480px) {
  .subjectWrap {
    padding: 0 5px;

    .v-chip {
      font-size: $fs-mini;
      height: 18px;
      background: $transparent-imp;
      margin-left: 0;

      .chipContent {
        margin: 0 -12px;
      }
      &::after {
        content: " : ";
      }
    }
    .subject {
      font-size: $fs-mini;
    }
  }
}
</style>
