var render = function render(){var _vm=this,_c=_vm._self._c;return _c('x-dialog',{attrs:{"cancel-text":"","confirm-text":"","close-sign":true,"max-width":_vm.previewWidth},on:{"confirm":e => _vm.$emit('confirm', e)},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"contentWrap"},[_c('span',{staticClass:"deviceIcon"},[_c('span',{on:{"click":function($event){return _vm.changePreviewWidth(_vm.defautSize.previewPcWidth)}}},[_c('x-button',_vm._b({class:{
            deviceButtonActive:
              _vm.previewContentWidth === _vm.defautSize.previewPcWidth
          },attrs:{"content":"desktop_windows"}},'x-button',_vm.deviceButtonScheme,false))],1),_c('span',{on:{"click":function($event){return _vm.changePreviewWidth(_vm.defautSize.previewMobileWidth)}}},[_c('x-button',_vm._b({class:{
            deviceButtonActive:
              _vm.previewContentWidth === _vm.defautSize.previewMobileWidth
          },attrs:{"content":"phone_android"}},'x-button',_vm.deviceButtonScheme,false))],1)]),_c('div',{staticClass:"mt-3 containerOfPreviewSec"},[_c('div',{staticClass:"subjectWrap"},[_c('v-chip',{attrs:{"label":"","disabled":""}},[_c('span',{staticClass:"chipContent"},[_c('v-avatar',{staticClass:"chipSubject hidden-xs-only"},[_c('v-icon',[_vm._v("mail")])],1),_vm._v(" Subject ")],1)]),_c('span',{staticClass:"subject"},[_vm._v(_vm._s(_vm.subject))])],1),_c('iframe',{ref:"previewIframe",staticStyle:{"min-height":"60vh"},attrs:{"width":_vm.previewContentWidth + 30,"frameborder":"0"}})])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }